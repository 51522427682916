import { Alert, FormControlLabel, InputLabel, MenuItem, Select, Stack, Switch } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LeavesActionType } from '@/stores/reducers/leavesActions';

import { DialogContainer } from '@/components/dialog-container/DialogContainer';
import { DatePickerWrapper } from '@/components/date-picker/DatePickerWrapper';
import { UiActionType } from '@/stores/reducers/uiSlice';
import { showSnackbar } from '@/utils/snackbar.util';
import { handleError } from '@/utils/api.util';
import { LeaveType, LeaveTypePolicy, EmployeeLeaveTypePolicy } from '@/domain/leave-type/LeaveType.model';
import { getLeaveTypes } from '@/domain/leave-type/LeaveType.service';
import { useAppDispatch, useAppSelector } from '@/stores/store';
import { batchUnassignLeaveTypePolicy, unassignLeaveTypePolicy } from '@/domain/employee-leave-type/EmployeeLeaveType.service';
import { getCurrentLocalDate, isValidDate } from '@/utils/datetime.util';

type Props = {
    userLeaveTypePolicies: EmployeeLeaveTypePolicy[];
    onPolicyUnassigned: (newPolicies: EmployeeLeaveTypePolicy[]) => void;
};

const defaultEndDate: LocalDate = '1990-01-01';

export const UnassignPolicyDialog: FC<Props> = ({ onPolicyUnassigned, userLeaveTypePolicies }) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const employeeIds = useAppSelector(state => state.ui.unassignLeaveTypeDialogUsersIdList);
    const leaveTypes = useAppSelector(state => state.leaves.leaveTypes);
    const open = useAppSelector(state => state.ui.unassignLeaveTypePolicyDialogOpen);
    const userId = useAppSelector(state => state.ui.unassignLeaveTypePolicyDialogUserId);

    const [selectedLeaveType, setSelectedLeaveType] = useState<LeaveType>();
    const [selectedLeaveTypePolicy, setSelectedLeaveTypePolicy] = useState<LeaveTypePolicy>();
    const [withCustomEndDate, setWithCustomEndDate] = useState<boolean>(false);

    const [endDate, setEndDate] = useState<LocalDate>(getCurrentLocalDate());
    const [error, setError] = useState<string>('');

    // clear the form when the dialog is closed
    useEffect(() => {
        if (!open) {
            setSelectedLeaveType(undefined);
            setSelectedLeaveTypePolicy(undefined);
            setError('');
        }
    }, [open]);

    // load the leave types when the dialog is opened
    useEffect(() => {
        if (open && leaveTypes === undefined && employeeIds) {
            getLeaveTypes()
                .then(leaveTypes => {
                    dispatch({ type: LeavesActionType.LEAVE_TYPES_LOADED, leaveTypes });
                })
                .catch(error => {
                    handleError(error);
                });
        }
    }, [open, employeeIds, dispatch, leaveTypes]);

    useEffect(() => {
        if (!selectedLeaveTypePolicy || !selectedLeaveType) {
            return;
        }
        setError('');
    }, [userLeaveTypePolicies, selectedLeaveType, selectedLeaveTypePolicy]);

    const isFormValid = () => {
        return selectedLeaveType;
    };

    const onSave = () => {
        if (error || !selectedLeaveType || !endDate) {
            return;
        }

        const endDateToUse = withCustomEndDate ? endDate : defaultEndDate;

        if (!employeeIds && userId) {
            unassignLeaveTypePolicy(userId, selectedLeaveType, endDateToUse)
                .then(userLeaveTypePolicies => {
                    showSnackbar(t('my_profile.messages.policy_unassigned'), 'success');
                    onPolicyUnassigned(userLeaveTypePolicies);
                    onClose();
                })
                .catch(error => {
                    handleError(error);
                });
        } else if (employeeIds.length) {
            batchUnassignLeaveTypePolicy(employeeIds, selectedLeaveType.id, endDateToUse)
                .then(() => {
                    showSnackbar(t('manage_people_page.messages.users_un_assigned'), 'success');
                    onClose();
                })
                .catch(error => {
                    handleError(error);
                });
        }
    };

    const onClose = () => dispatch({ type: UiActionType.UNASSIGN_LEAVE_TYPE_DIALOG_OPEN, open: false });

    const allLeaveTypes = userLeaveTypePolicies.map(ult => ult.leaveType);
    const uniqueLeaveTypes: LeaveType[] = [...new Map(allLeaveTypes.map(item => [item.id, item])).values()];
    const displayLeaveTypes = leaveTypes ?? [];
    const displayUserAssignedLeaveTypes = uniqueLeaveTypes;

    return (
        <DialogContainer
            open={open}
            onClose={() => onClose()}
            title={t('user_leave_type_dialog.unassign_policy')}
            onSave={onSave}
            primaryActionDisabled={!isFormValid()}
        >
            <Stack gap={1}>
                <InputLabel id='leaveType'>{t('user_leave_type_dialog.leave_type')}</InputLabel>
                <Select
                    labelId='leaveType'
                    id='leave_type_select'
                    value={selectedLeaveType?.id}
                    fullWidth
                    onChange={event => {
                        const value = event.target.value;
                        if (value) {
                            const newValue = uniqueLeaveTypes.find(lt => lt.id === value);
                            if (newValue) {
                                setSelectedLeaveType(newValue);
                                if (newValue.policies.length === 1) {
                                    setSelectedLeaveTypePolicy(newValue.policies[0]);
                                } else {
                                    setSelectedLeaveTypePolicy(undefined);
                                }
                            } else if (employeeIds) {
                                const newValue = displayLeaveTypes.find(lt => lt.id === value);
                                setSelectedLeaveType(newValue);
                            }
                        }
                    }}
                >
                    {!employeeIds &&
                        displayUserAssignedLeaveTypes.map(lt => {
                            return (
                                <MenuItem key={lt.id} value={lt.id}>
                                    {lt.title}
                                </MenuItem>
                            );
                        })}
                    {employeeIds &&
                        displayLeaveTypes.map(lt => {
                            return (
                                <MenuItem key={lt.id} value={lt.id}>
                                    {lt.title}
                                </MenuItem>
                            );
                        })}
                </Select>
                <FormControlLabel
                    label={t('user_leave_type_dialog.end_date_switch')}
                    labelPlacement='end'
                    control={<Switch color='primary' onChange={event => setWithCustomEndDate(event.target.checked)} />}
                />
                {withCustomEndDate && (
                    <FormControlLabel
                        label={t('user_leave_type_dialog.end_date')}
                        labelPlacement='top'
                        control={
                            <DatePickerWrapper
                                value={endDate}
                                minDate={undefined}
                                onChange={newValue => {
                                    if (isValidDate(newValue)) {
                                        setEndDate(newValue);
                                    }
                                }}
                            />
                        }
                    />
                )}
                {error && <Alert severity='error'>{error}</Alert>}
            </Stack>
        </DialogContainer>
    );
};
