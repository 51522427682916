import { AllowanceType, EmployeeLeaveTypePolicy } from '@/domain/leave-type/LeaveType.model';
import { MenuItemProps, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { canCorrectEmployeeBalance, canManagePendingLeaveRequests, hasConfigureLeavePolicy } from '@/domain/permission/Permission.service';
import { LeaveRequestDialog } from '@/page/leave/leave-request-dialog/LeaveRequestDialog';
import { UiActionType } from '@/stores/reducers/uiSlice';
import { AssignPolicyDialog } from './Dialogs/AssignPolicyDialog';
import { UnassignPolicyDialog } from './Dialogs/UnassignPolicyDialog';

import { SplitButtonGroup } from '@/components/button-group/SplitButtonGroup';
import { LeaveCorrectionCreateMutation } from '@/domain/leave-correction/LeaveCorrection.model';
import { leaveCorrectionService } from '@/domain/leave-correction/LeaveCorrection.service';
import { LeaveTypeHistory } from '@/domain/leave-type-history/LeaveTypeHistory.model';
import { useGetEmployeeById } from '@/hooks/employee/Employee.hook';
import { LeaveTypeCard } from '@/page/employee-profile/employee-profile-leave/LeaveTypeCard';
import { LeaveCorrectionDialog, LeaveCorrectionValues } from '@/page/leave/leave-correction-dialog/LeaveCorrectionDialog';
import { useAppDispatch, useAppSelector } from '@/stores/store';
import { desktopBlockVisible } from '@/theme/responsive';
import { handleError } from '@/utils/api.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { Add01Icon } from 'hugeicons-react';
import { formatInDefaultDate } from '@/utils/datetime.util';

type MyAllowanceProps = {
    employeeId: number;
    userLeaveTypePolicies: EmployeeLeaveTypePolicy[];
    onPoliciesUpdated: (newPolicies: EmployeeLeaveTypePolicy[]) => void;
    onChange: () => void;
    leaveTypeHistories: LeaveTypeHistory[];
};

export const MyAllowance: FC<MyAllowanceProps> = ({ employeeId, userLeaveTypePolicies, onPoliciesUpdated, onChange, leaveTypeHistories }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const policies = useAppSelector(state => state.currentEmployee.grantedPolicies);
    const theme = useTheme();
    const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));
    const [employeeIdForLeaveRequestCreation, setEmployeeIdForLeaveRequestCreation] = useState<number>();
    const [openLeaveCorrectionDialog, setOpenLeaveCorrectionDialog] = useState(false);
    const { data: activeEmployee } = useGetEmployeeById(employeeId);

    // THis is a hack, to not make a specific api call to get the cycle end date
    // Each leave type history has two cycles, one for the current cycle and one for the next cycle
    const cycleEndDate = leaveTypeHistories?.[0]?.cycles?.[0]?.leaveCycle?.cycleEndDate;

    const showLeaveTypeHistoryCard = (history: LeaveTypeHistory) =>
        history.leaveType.displayCounter == 'ALWAYS' || (history.usedAmountInMinutes > 0 && history.leaveType.displayCounter == 'SHOW_IF_THERE_IS_LEAVES');

    leaveTypeHistories.sort((columnA, columnB) => {
        return columnA.leaveType.order - columnB.leaveType.order;
    });

    const leaveTypeHistoriesToShow = leaveTypeHistories.filter(history => showLeaveTypeHistoryCard(history));
    const leaveTypeHistoriesNotUnlimited = leaveTypeHistoriesToShow.filter(lth => lth.leaveType.allowanceType === AllowanceType.NOT_UNLIMITED);

    const getLeaveMenuItems = () => {
        const leaveMenuItems: MenuItemProps[] = [];
        if (userLeaveTypePolicies.length !== 0) {
            leaveMenuItems.push({
                title: t('my_leaves.leave_label'),
                onClick: () => {
                    setEmployeeIdForLeaveRequestCreation(employeeId);
                },

                color: 'primary',
            });
        }

        if (hasConfigureLeavePolicy(policies)) {
            leaveMenuItems.push({
                title: t('my_leaves.assign_policy'),
                onClick: () => {
                    dispatch({
                        type: UiActionType.ASSIGN_LEAVE_TYPE_DIALOG_OPEN,
                        userId: employeeId,
                        open: true,
                    });
                },
                key: 'assign-policy',
            });
            if (userLeaveTypePolicies.length > 0) {
                leaveMenuItems.push({
                    title: t('my_leaves.unassign_policy'),
                    disabled: !userLeaveTypePolicies?.length,
                    onClick: () => {
                        dispatch({
                            type: UiActionType.UNASSIGN_LEAVE_TYPE_DIALOG_OPEN,
                            userId: employeeId,
                            open: true,
                        });
                    },
                    key: 'unassign-policy',
                });
            }
        }

        if (leaveTypeHistoriesNotUnlimited.length > 0 && canCorrectEmployeeBalance(policies, employeeId)) {
            leaveMenuItems.push({
                title: t('my_leaves_history.add_correction'),
                onClick: () => {
                    setOpenLeaveCorrectionDialog(true);
                },
                key: 'add-correction',
            });
        }
        return leaveMenuItems;
    };

    const leaveMenuItems = getLeaveMenuItems();

    const handleLeaveCorrectionCreate = async (values: LeaveCorrectionValues) => {
        const { leaveType, ...rest } = values;
        const mutation: LeaveCorrectionCreateMutation = {
            ...rest,
            employeeId,
            leaveTypeId: leaveType.id,
        };
        try {
            await leaveCorrectionService.createLeaveCorrection(mutation);
            setOpenLeaveCorrectionDialog(false);
            showSnackbar(t('add_correction_dialog.messages.correction_added'), 'success');
            onChange();
        } catch (error) {
            handleError(error);
        }
    };

    return (
        <>
            <AssignPolicyDialog
                onPolicyAssigned={(newPolicies: EmployeeLeaveTypePolicy[]) => {
                    onPoliciesUpdated(newPolicies);
                    onChange();
                }}
            />

            {userLeaveTypePolicies?.length > 0 && (
                <UnassignPolicyDialog
                    userLeaveTypePolicies={userLeaveTypePolicies}
                    onPolicyUnassigned={(newPolicies: EmployeeLeaveTypePolicy[]) => {
                        onPoliciesUpdated(newPolicies);
                        onChange();
                    }}
                />
            )}
            <Stack direction='column' spacing={2}>
                <Stack direction='row' alignItems='center' spacing={1} justifyContent={'space-between'}>
                    {!!leaveTypeHistories.length && (
                        <Typography variant='h1' sx={desktopBlockVisible}>
                            {t('my_leaves.balance_at', {
                                endDate: formatInDefaultDate(cycleEndDate),
                            })}
                        </Typography>
                    )}
                    {canManagePendingLeaveRequests(policies, employeeId) && (
                        <SplitButtonGroup
                            buttonProps={{
                                startIcon: <Add01Icon size={20} />,
                                size: 'small',
                            }}
                            menuItemsProps={[...leaveMenuItems]}
                            ariaLabel={t('my_leaves.request_leave')}
                        />
                    )}
                </Stack>
                <Stack direction='row' gap={2} flexWrap={isSmallDevice ? 'nowrap' : 'wrap'} overflow={'auto'}>
                    {leaveTypeHistoriesToShow.map(history => (
                        <LeaveTypeCard
                            key={history.leaveType.id}
                            employeeId={employeeId}
                            leaveType={history.leaveType}
                            availableAmountInMinutes={history.availableAmountInMinutes}
                            usedAmountInMinutes={history.usedAmountInMinutes}
                            allowanceInMinutes={history.allowanceInMinutes}
                            availableAmountInDays={history.availableAmountInDays}
                            usedAmountInDays={history.usedAmountInDays}
                            allowanceInDays={history.allowanceInDays}
                        />
                    ))}
                </Stack>
            </Stack>
            {!!employeeIdForLeaveRequestCreation && (
                <LeaveRequestDialog
                    open={true}
                    onClose={() => setEmployeeIdForLeaveRequestCreation(undefined)}
                    onSave={() => {
                        setEmployeeIdForLeaveRequestCreation(undefined);
                        onChange();
                    }}
                    employeeId={employeeIdForLeaveRequestCreation}
                />
            )}

            {openLeaveCorrectionDialog && !!activeEmployee?.currentWorkingPattern && (
                <LeaveCorrectionDialog
                    open={true}
                    workingPattern={activeEmployee.currentWorkingPattern}
                    leaveTypes={leaveTypeHistoriesNotUnlimited.map(lth => lth.leaveType)}
                    onSave={handleLeaveCorrectionCreate}
                    onClose={() => {
                        setOpenLeaveCorrectionDialog(false);
                    }}
                />
            )}
        </>
    );
};
