import { createDefaultLeaveTypePolicy } from '@/domain/leave-request/LeaveRequest.service';
import { AllowanceType, LeaveType, LeaveTypePolicy, EmployeeLeaveTypePolicy } from '@/domain/leave-type/LeaveType.model';
import { LeavesActionType } from '@/stores/reducers/leavesActions';
import { Alert, FormControlLabel, InputLabel, MenuItem, Select, Stack, Switch } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DatePickerWrapper } from '@/components/date-picker/DatePickerWrapper';
import { DialogContainer } from '@/components/dialog-container/DialogContainer';
import { assignLeaveTypePolicy, batchAssignLeaveTypePolicy } from '@/domain/employee-leave-type/EmployeeLeaveType.service';
import { getLeaveTypes } from '@/domain/leave-type/LeaveType.service';
import { UiActionType } from '@/stores/reducers/uiSlice';
import { useAppSelector } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { getCurrentLocalDate, getStartOfYear, isValidDate } from '@/utils/datetime.util';

import { showSnackbar } from '@/utils/snackbar.util';
import { useDispatch } from 'react-redux';

type Props = {
    onPolicyAssigned: (newPolicy: EmployeeLeaveTypePolicy[]) => void;
};

// By default, the apply from date is the first day of the current year
const defaultApplyFromDate = getStartOfYear();

export const AssignPolicyDialog: FC<Props> = ({ onPolicyAssigned }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const employeeIds = useAppSelector(state => state.ui.assignLeaveTypeDialogUsersIdList);
    const leaveTypes = useAppSelector(state => state.leaves.leaveTypes);
    const open = useAppSelector(state => state.ui.assignLeaveTypeDialogOpen);
    const userId = useAppSelector(state => state.ui.assignLeaveTypeDialogUserId);

    const [selectedLeaveType, setSelectedLeaveType] = useState<LeaveType>();
    const [selectedLeaveTypePolicy, setSelectedLeaveTypePolicy] = useState<LeaveTypePolicy>();
    const [withCustomApplyFromDate, setWithCustomApplyFromDate] = useState<boolean>(false);
    const [applyFromDate, setApplyFromDate] = useState<LocalDate>(getCurrentLocalDate());
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (open) {
            if (leaveTypes === undefined) {
                setLoading(true);
                getLeaveTypes()
                    .then(leaveTypes => {
                        dispatch({ type: LeavesActionType.LEAVE_TYPES_LOADED, leaveTypes });
                    })
                    .catch(error => {
                        handleError(error);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        } else {
            setSelectedLeaveType(undefined);
            setSelectedLeaveTypePolicy(undefined);
            setApplyFromDate(getCurrentLocalDate());
            setError('');
        }
    }, [open, leaveTypes, dispatch]);

    if (!leaveTypes || loading) {
        return <></>;
    }

    const isFormValid = () => {
        if (!selectedLeaveType) {
            return false;
        }
        if (!applyFromDate || !isValidDate(applyFromDate)) {
            return false;
        }
        if (selectedLeaveType.allowanceType !== AllowanceType.UNLIMITED && !selectedLeaveTypePolicy) {
            return false;
        }
        return !error;
    };

    const onSave = () => {
        if (!applyFromDate || error || !selectedLeaveType || (selectedLeaveType.allowanceType !== AllowanceType.UNLIMITED && !selectedLeaveTypePolicy)) {
            return;
        }

        let leaveTypePolicy = selectedLeaveTypePolicy ?? createDefaultLeaveTypePolicy();
        const applyFromDateToUse = withCustomApplyFromDate ? applyFromDate : defaultApplyFromDate;

        // if the selected leave type has only one policy, we assign it by default
        if (selectedLeaveType.policies.length === 1) {
            leaveTypePolicy = selectedLeaveType.policies[0];
        }

        if (!employeeIds && userId) {
            const userLeaveType: EmployeeLeaveTypePolicy = {
                id: 0,
                leaveType: selectedLeaveType,
                leaveTypePolicy: leaveTypePolicy,
                applyFrom: applyFromDateToUse,
                endDate: undefined,
            };
            assignLeaveTypePolicy(userId, userLeaveType)
                .then(userLeaveTypePolicies => {
                    showSnackbar(t('my_profile.messages.leave_type_added'), 'success');
                    onPolicyAssigned(userLeaveTypePolicies);
                    onClose();
                })
                .catch(error => {
                    handleError(error);
                });
        } else if (employeeIds.length) {
            if (!leaveTypePolicy?.id) {
                return;
            }
            batchAssignLeaveTypePolicy(employeeIds, selectedLeaveType.id, leaveTypePolicy.id, applyFromDateToUse)
                .then(() => {
                    showSnackbar(t('manage_people_page.messages.users_assigned'), 'success');
                    onClose();
                })
                .catch(error => {
                    handleError(error);
                });
        }
    };

    const onClose = () => dispatch({ type: UiActionType.ASSIGN_LEAVE_TYPE_DIALOG_OPEN, open: false });

    return (
        <DialogContainer
            open={open}
            onClose={() => onClose()}
            title={t('user_leave_type_dialog.add_leave_type')}
            onSave={onSave}
            primaryActionDisabled={!isFormValid()}
        >
            <Stack gap={1}>
                <InputLabel id='leaveType'>{t('user_leave_type_dialog.leave_type')}</InputLabel>
                <Select
                    labelId='leaveType'
                    id='leave_type_select'
                    value={selectedLeaveType?.id}
                    fullWidth
                    onChange={event => {
                        const value = event.target.value;
                        if (value) {
                            const newValue = leaveTypes.find(lt => lt.id === value);
                            if (newValue) {
                                setSelectedLeaveType(newValue);
                                if (newValue.policies.length === 1) {
                                    setSelectedLeaveTypePolicy(newValue.policies[0]);
                                } else {
                                    setSelectedLeaveTypePolicy(undefined);
                                }
                            }
                        }
                    }}
                >
                    {leaveTypes.map(lt => {
                        return (
                            <MenuItem key={lt.id} value={lt.id}>
                                {lt.title}
                            </MenuItem>
                        );
                    })}
                </Select>
                {/* we only display the policy select if there are more than one policy otherwise the policy is automatically selected */}
                {(selectedLeaveType?.policies?.length ?? 0) > 1 && (
                    <>
                        <InputLabel id='policy'>{t('user_leave_type_dialog.policy')}</InputLabel>
                        <Select
                            labelId='policy'
                            id='policy_select'
                            value={selectedLeaveTypePolicy?.id}
                            disabled={!selectedLeaveType}
                            fullWidth
                            onChange={event => {
                                if (event.target.value && selectedLeaveType) {
                                    const newValue = selectedLeaveType?.policies.find(p => p.id === event.target.value);
                                    setSelectedLeaveTypePolicy(newValue);
                                }
                            }}
                        >
                            {selectedLeaveType?.policies.map(p => (
                                <MenuItem key={p.id} value={p.id}>
                                    {p.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </>
                )}

                <FormControlLabel
                    label={t('user_leave_type_dialog.apply_from_switch')}
                    labelPlacement='end'
                    control={<Switch color='primary' onChange={event => setWithCustomApplyFromDate(event.target.checked)} />}
                />

                {withCustomApplyFromDate && (
                    <FormControlLabel
                        label={t('user_leave_type_dialog.apply_from')}
                        labelPlacement='top'
                        control={
                            <DatePickerWrapper
                                value={applyFromDate}
                                onChange={newValue => {
                                    if (isValidDate(newValue)) {
                                        setApplyFromDate(newValue);
                                    }
                                }}
                            />
                        }
                    />
                )}
                {error && <Alert severity='error'>{error}</Alert>}
            </Stack>
        </DialogContainer>
    );
};
